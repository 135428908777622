<template>
	<div>
		<div class="jobfair-units">
			<div class="choice-units">
				<table class="units-table">
					<tr>
						<td class="gd">入口</td>
						<td class="gd" rowspan="12">过道</td>
						<td id="A21">A21</td>
						<td id="B22">B22</td>
						<td class="gd" rowspan="12">过道</td>
						<td id="B45">B45</td>
						<td id="C46">C46</td>
						<td class="gd" rowspan="12">过道</td>
						<td id="C69">C69</td>
						<td id="D70">D70</td>
						<td class="gd" rowspan="12">过道</td>
						<td id="D91">D91</td>
						<td id="D92">D92</td>
					</tr>
					<tr>
						<td id="A01">A01</td>
						<td id="A20">A20</td>
						<td id="B23">B23</td>
						<td id="B44">B44</td>
						<td id="C47">C47</td>
						<td id="C68">C68</td>
						<td id="D71">D71</td>
						<td id="D90">D90</td>
						<td id="D93">D93</td>
					</tr>
					<tr>
						<td id="A02">A02</td>
						<td id="A19">A19</td>
						<td id="B24">B24</td>
						<td id="B43">B43</td>
						<td id="C48">C48</td>
						<td id="C67">C67</td>
						<td id="D72">D72</td>
						<td id="D89">D89</td>
						<td id="D94">D94</td>
					</tr>
					<tr>
						<td id="A03">A03</td>
						<td id="A18">A18</td>
						<td id="B25">B25</td>
						<td id="B42">B42</td>
						<td id="C47">C47</td>
						<td id="C68">C68</td>
						<td id="D73">D73</td>
						<td id="D88">D88</td>
						<td id="D95">D95</td>
					</tr>
					<tr>
						<td id="A04">A04</td>
						<td id="A17">A17</td>
						<td id="B26">B26</td>
						<td id="B41">B41</td>
						<td id="C48">C48</td>
						<td id="C67">C67</td>
						<td id="D74">D74</td>
						<td id="D87">D87</td>
						<td id="D96">D96</td>
					</tr>
					<tr>
						<td class="gd"></td>
						<td id="A16">A16</td>
						<td id="B27">B27</td>
						<td id="B40">B40</td>
						<td id="C49">C49</td>
						<td id="C66">C66</td>
						<td id="D75">D75</td>
						<td id="D86">D86</td>
						<td id="D97">D97</td>
					</tr>
					<tr>
						<td class="gd"></td>
						<td id="A15">A15</td>
						<td id="B28">B28</td>
						<td id="B39">B39</td>
						<td id="C50">C50</td>
						<td id="C65">C65</td>
						<td id="D76">D76</td>
						<td id="D85">D85</td>
						<td id="D98">D98</td>
					</tr>
					<tr>
						<td id="A05">A05</td>
						<td id="A14">A14</td>
						<td id="B29">B29</td>
						<td id="B38">B38</td>
						<td id="C51">C51</td>
						<td id="C64">C64</td>
						<td id="D77">D77</td>
						<td id="D84">D84</td>
						<td id="D99">D99</td>
					</tr>
					<tr>
						<td id="A06">A06</td>
						<td id="A13">A13</td>
						<td id="B30">B30</td>
						<td id="B37">B37</td>
						<td id="C52">C52</td>
						<td id="C63">C63</td>
						<td id="D78">D78</td>
						<td class="gd"></td>
						<td class="gd"></td>
					</tr>
					<tr>
						<td id="A07">A07</td>
						<td id="A12">A12</td>
						<td id="B31">B31</td>
						<td id="B36">B36</td>
						<td id="C53">C53</td>
						<td id="C62">C62</td>
						<td id="D79">D79</td>
						<td class="gd"></td>
						<td class="gd"></td>
					</tr>
					<tr>
						<td id="A08">A08</td>
						<td id="A11">A11</td>
						<td id="B32">B32</td>
						<td id="B35">B35</td>
						<td id="C54">C54</td>
						<td id="C61">C61</td>
						<td id="D80">D80</td>
						<td id="D83">D83</td>
						<td class="gd"></td>
					</tr>
					<tr>
						<td id="A09">A09</td>
						<td id="A10">A10</td>
						<td id="B33">B33</td>
						<td id="B34">B34</td>
						<td id="C57">C57</td>
						<td id="C58">C58</td>
						<td id="D81">D81</td>
						<td id="D82">D82</td>
						<td class="gd"></td>
					</tr>
				</table>
			</div>
			<div class="units-table">
				<div class="units-table-thead">
					<div class="th-1f">序号</div>
					<div class="th-2s">展位名称</div>
					<div class="th-3t">展位费</div>
					<div class="th-4f">应缴费用</div>
				</div>
				<div class="units-table-tbody">
					<div class="units-table-tr" :key="index" v-for="(item, index) in Xunitsdata">
						<div class="th-1f">{{ index + 1 }}</div>
						<div class="th-2s">
							<span>{{ item.cZanw }}</span>
						</div>
						<div class="th-3t">{{ item.iFee }}</div>
						<div class="th-4f">{{ item.iFee }}</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 按钮下一步start -->
		<div class="jobfair-next">
			<el-button @click="back">上一步</el-button>
			<el-button type="primary" @click="offlinezanwid()">保存并下一步</el-button>
		</div>
	</div>
</template>
<script>
	import {
		offlinezanw,
		offlinezanwid
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {
			id: {
				type: Number,
			},
			type: {
				type: Boolean,
			},
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				userinfo: "",
				unitsdata: [],
				Xunitsdata: [],
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
		},
		watch: {
			id(val) {
				if (!isnull(val)) {
					this.offlinezanw();
				}
			},
		},
		mounted() {
			if (!isnull(this.id)) {
				this.offlinezanw();
			}
			this.$nextTick(() => {
				var _this = this;
				$(".units-table td:not(.gd)").addClass("bg-nosit")
				$(".units-table td").on("click", function(d) {
					$(".units-table td.bg-sit").removeClass("bg-sit")
					$(".units-table td#" + d.currentTarget.id).addClass("bg-sit")
					_this.Xunitsdata = [];
					_this.unitsdata.forEach((element, i) => {
						if (element.cZanw == d.currentTarget.id)
							_this.Xunitsdata.push(element);
					});
				})
			})
		},
		methods: {
			//获取展位信息
			offlinezanw() {
				var _this = this;
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
				};
				offlinezanw(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							/*element.type = element.iComid ? 3 : 1;
							if (element.iComid == _this.userinfo.coM_ID) {
								element.type = 2;
								this.Xunitsdata = [];
								this.Xunitsdata.push(element);
							}*/
							if (element.iComid) {
								if (element.iComid == _this.userinfo.coM_ID) {
									this.Xunitsdata.push(element);
									$(".units-table td#" + element.cZanw).removeClass("bg-nosit").addClass(
										"bg-sited")
								} else
									$(".units-table td#" + element.cZanw).removeClass("bg-nosit").addClass(
										"bg-temp")
							} else
								$(".units-table td#" + element.cZanw).removeClass("bg-nosit")
						});
						_this.unitsdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//选取展位
			unitschange(item, index) {
				if (this.unitsdata[index].type != 3) {
					this.Xunitsdata.splice(0, 1);
					// this.unitsdata[index].type = this.unitsdata[index].type == 1 ? 2 : 1;
					this.unitsdata.forEach((element, i) => {
						if (item.id == element.id) {
							if (element.type == 1) {
								this.Xunitsdata.push(item);
								element.type = 2;
							} else {
								element.type = 1;
							}
						} else {
							element.type = 1;
						}
					});

					// if (this.Xunitsdata.length < 1) {
					//   this.Xunitsdata.push(item);
					// } else {
					//   //   var flag = true;
					//   //   this.Xunitsdata.forEach((element, i) => {
					//   if (this.Xunitsdata[0].id == item.id) {
					//     this.Xunitsdata.splice(i, 1);
					//   } else {
					//     this.Xunitsdata.push(item);
					//   }
					//   //   });
					//   //   if (flag) {
					//   //     this.Xunitsdata.push(item);
					//   //   }
					// }
				}
			},

			//保存展位信息
			offlinezanwid() {
				var _this = this;
				// var zid = "";
				if (_this.Xunitsdata.length < 1) {
					_this.$message.warning("请选取至少一个展位");
					return;
				}
				// this.Xunitsdata.forEach((element) => {
				//   zid = zid + element.id + ",";
				// });
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
					zid: _this.Xunitsdata[0].id,
				};
				offlinezanwid(par).then((res) => {
					if (res.success) {
						this.$emit("zanwBind", {
							data: true
						});
						// if (_this.type) {
						//   _this.confirmonlinecompanystatus();
						// } else {
						//   _this.confirmoffline();
						// }
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			back() {
				this.$emit("back", {
					i: 1
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.jobfair-units {
		transition: all 0.5s;
		margin-bottom: 12px;

		.choice-units {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;
			display: flex;
			flex-wrap: wrap;

			.units-table {
				width: 100%;

				td {
					height: 50px;
					text-align: center;
					font-size: 14px;
					color: #787878;
					border: 1px solid #000;
					cursor: pointer;
				}

				td.gd {
					cursor: no-drop;
					border: 0px solid #000;
				}

				.bg-temp {
					cursor: not-allowed;
				}

				.bg-sited {
					cursor: pointer;
					background-color: #f00;
					color: #fff
				}

				.bg-sit {
					cursor: pointer;
					background-color: #f00;
					color: #fff
				}

				.bg-nosit {
					cursor: no-drop;
					background-color: #f00;
					color: #fff
				}

			}

			.units-item {
				width: 60px;
				height: 80px;

				.units-img {
					height: 50px;
					width: 50px;
					margin: 0px auto;
				}

				.bg-temp {
					background: url(../../assets/img/unit3.png) center center no-repeat;
					background-size: 80%;
					cursor: not-allowed;
				}

				.bg-sited {
					background: url(../../assets/img/unit2.png) center center no-repeat;
					background-size: 80%;
					cursor: pointer;
				}

				.bg-sit {
					background: url(../../assets/img/units1.png) center center no-repeat;
					background-size: 80%;
					cursor: pointer;
				}

				.units-num {
					text-align: center;
					font-size: 14px;
					color: #787878;
				}
			}
		}

		.units-table {
			background: #fff;
			padding: 16px 24px;
			margin-bottom: 12px;

			.units-table-thead {
				background: #eee;
				color: #000;
				text-align: center;
				display: flex;
				padding: 12px 0;

				.th-1f {
					width: 80px;
				}

				.th-2s {
					width: 160px;
				}

				.th-3t {
					width: 160px;
				}

				.th-4f {
					flex: 1;
				}
			}

			.units-table-tbody {
				.units-table-tr {
					color: #666;
					text-align: center;
					display: flex;
					padding: 12px 0;
					border-top: 1px solid #eee;

					.th-1f {
						width: 80px;
					}

					.th-2s {
						width: 160px;

						span {
							padding: 5px 12px;
							border: 1px solid #fd7d4a;
							background: #fdefea;
							color: #fd7d4a;
						}
					}

					.th-3t {
						width: 160px;
					}

					.th-4f {
						flex: 1;
					}
				}
			}
		}
	}

	.jobfair-next {
		background: #fff;
		padding: 12px 15px;
		text-align: center;

		.el-button+.el-button {
			margin-left: 30px;
		}

		.el-button {
			border-radius: 0;
			width: 200px;
		}
	}
</style>
