<template>
	<div class="">
		<div class="jobfair-content">
			<div class="jobfair-item" v-for="(item, i) in postdata" :key="i">
				<div class="item-list">
					<div class="post-name">{{ item.zwmc }}</div>
					<div class="post-salary" v-if="item.cMy != 1">
						{{ item.cDyb ? item.cDyb : "" }}
						{{ item.cDye && item.cDyb ? "-" : "" }}
						{{ item.cDye ? item.cDye : "" }}
					</div>
					<div class="post-salary" v-else>面议</div>
					<div class="deliver-btn">
						<el-button @click="zphpostedit(item)" type="primary">编辑</el-button>
					</div>
				</div>
				<div class="item-list">
					<div class="post-detail">
						<span
							v-if="item.cJZ != 0 && item.cJZ != null">{{ item.cJZ != 0 && item.cJZ != null ? "紧缺工种" : "" }}
							<b>|</b></span>
						<span v-if="item.xueli">{{ item.xueli }} <b>|</b></span><span v-if="item.zhiwei">
							{{ item.zhiwei }}<b>|</b></span><span
							v-if="item.jinquedu">{{ item.jinquedu }}<b>|</b></span><span v-if="item.cZy">
							{{ item.cZy }}</span>
					</div>
					<div class="deliver-btn">
						<el-button @click="delonlineposicompany(item.id)">删除</el-button>
					</div>
				</div>
				<div class="item-list">
					<div class="post-welfare han">
						<!-- <span v-for="(view, j) in item.oF_FLZL_QT_list" :key="j">{{
                    view
                  }}</span> -->
						{{ item.cGwyq }}
					</div>
				</div>
			</div>
		</div>
		<el-empty :image-size="200" v-if="postdata.length < 1"></el-empty>
		<div class="page">
			<el-pagination @size-change="SizeChange" @current-change="CurrentChange" :current-page="PageNumber"
				:page-sizes="[10, 20, 30, 40]" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total">
			</el-pagination>
		</div>
		<!-- 按钮下一步start -->
		<div class="jobfair-next">
			<div>
				<el-button type="primary" @click="synchronous">同步岗位</el-button>
				<el-button type="success" @click="zphpostAdd">新增岗位</el-button>
			</div>
			<!-- <div>
				<el-button @click="back">上一步</el-button>

				<el-button type="primary" @click="postBind">下一步</el-button>
			</div> -->
		</div>

		<!-- 招聘会岗位 -->
		<el-drawer title="招聘会岗位" size="50%" :wrapperClosable="false" :visible.sync="zphdrawer" :direction="direction">
			<div class="postadd">
				<div class="li">
					<div class="left">岗位名称:</div>
					<div class="right">
						<input type="text" v-model="zphpost.Zwmc" placeholder="请输入" />
					</div>
				</div>

				<div class="li">
					<div class="left">招聘人数:</div>
					<div class="right">
						<el-input-number v-model="zphpost.cZprs" :min="1" label="招聘人数"></el-input-number>
					</div>
				</div>
				<div class="li">
					<div class="left">岗位类型:</div>
					<div class="right">
						<el-select class="labelselect" v-model="zphsxdata[0].data" @change="hyChange($event, 0)"
							placeholder="请选择">
							<el-option v-for="item in zphsxdata[0].list" :key="item.code_name" :label="item.code_name"
								:value="item.code_value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="li lix">
					<div class="left">学历要求:</div>
					<div class="right">
						<el-select class="labelselect" v-model="zphsxdata[1].data" @change="hyChange($event, 1)"
							placeholder="请选择">
							<el-option v-for="item in zphsxdata[1].list" :key="item.code_name" :label="item.code_name"
								:value="item.code_value">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="li lixs">
					<div class="left">是否紧缺:</div>
					<div class="right">
						<el-switch v-model="zphpost.cJZ" active-color="#13ce66" inactive-color="#ff4949">
						</el-switch>
					</div>
				</div>
				<div class="li">
					<div class="left">起始薪资:</div>
					<div class="right">
						<input type="number" min="1" class="inputx" :readonly="zphpost.cMy ? true : false"
							v-model="zphpost.cDyb" placeholder="请输入起始薪资" />
						-
						<input class="inputx" type="number" :min="zphpost.cDyb" :readonly="zphpost.cMy ? true : false"
							v-model="zphpost.cDye" placeholder="请输入结束薪资" />
					</div>
				</div>
				<div class="li">
					<div class="left">是否面议:</div>
					<div class="right">
						<el-switch v-model="zphpost.cMy" active-color="#13ce66" inactive-color="#ff4949">
						</el-switch>
					</div>
				</div>

				<div class="li">
					<div class="left">专业要求:</div>
					<div class="right">
						<input type="text" v-model="zphpost.cZy" placeholder="请输入" />
					</div>
				</div>
				<div class="li">
					<div class="left">招聘对象:</div>
					<div class="right">
						<el-radio-group v-model="zphpost.cHbys">
							<el-radio label="1">应届毕业生</el-radio>
							<el-radio label="0">社会精英</el-radio>
						</el-radio-group>
					</div>
				</div>

				<div class="ul">
					<div class="left">岗位描述:</div>
					<div class="right">
						<textarea v-model="zphpost.cGwyq" placeholder="请在这里输入岗位要求" cols="30" rows="20"></textarea>
					</div>
				</div>
				<div class="btn">
					<div class="preservation" @click="onlineposicompany">保存</div>
					<div class="cancel" @click="zphdrawer = false">取消</div>
				</div>
			</div>
		</el-drawer>
	</div>
</template>
<script>
	import {
		onlineposicompanylist,
		onlineposicompany,
		delonlineposicompany,
		hy,
		posilistall,
	} from "@/api/company";
	import {
		isnull
	} from "@/assets/js/index";
	import utils from "@/assets/js/utils";
	import global from "@/assets/js/globalconfig";
	export default {
		props: {
			id: {
				type: Number,
			},
			type: {
				type: Boolean,
			},
			cDaxing: {
				type: Boolean,
			},
		},
		data() {
			return {
				defimgurl: global.baseimgurl,
				direction: "rtl",
				userinfo: "",
				postdata: [],
				total: 0,
				PageNumber: 1,
				PageSize: 10,
				zphdrawer: false,
				zphpost: "",
				zphsxdata: [{
						name: "招聘会职位类别",
						index: 0,
						list: [],
						data: ""
					},
					{
						name: "招聘会学历",
						index: 0,
						list: [],
						data: ""
					},
					// { name: "招聘会紧缺度", index: 0, list: [], data: "" },
				],
				xzList: [],
				xzdata: "",
				edit: false,
			};
		},
		created() {
			this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
			for (const i in this.zphsxdata) {
				this.hy(i);
			}
			// this.posilistall();
		},
		watch: {
			id(val) {
				if (!isnull(val)) {
					this.onlineposicompanylist();
				}
			},
		},
		mounted() {
			if (!isnull(this.id)) {
				this.onlineposicompanylist();
			}
		},
		methods: {
			//招聘会下拉数据
			hy(i) {
				var _this = this;
				var par = {
					field: _this.zphsxdata[i].name,
				};
				hy(par).then((res) => {
					if (res.success) {
						_this.zphsxdata[i].list = res.data;
						res.data.forEach((element, index) => {
							switch (i) {
								case "0":
									if (element.cCode == _this.zphpostAdd.cHy) {
										_this.zphsxdata[i].data = element.cName;
										_this.zphsxdata[i].index = index;
									}
									break;
								case "1":
									if (element.cCode == _this.zphpostAdd.cXl) {
										_this.zphsxdata[i].data = element.cName;
										_this.zphsxdata[i].index = index;
									}
									break;
									// case "2":
									//   if (element.cCode == _this.zphpostAdd.cJZ) {
									//     _this.zphsxdata[i].data = element.cName;
									//     _this.zphsxdata[i].index = index;
									//   }
									//   break;

								default:
									break;
							}
						});
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			hyChange(e, i) {
				var _this = this;
				_this.zphsxdata[i].list.forEach((element) => {
					if (element.code_value == e) {
						_this.zphsxdata[i].data = element.code_name;
					}
				});
				switch (i) {
					case 0:
						_this.zphpost.cHy = e;

						break;
					case 1:
						_this.zphpost.cXl = e;

						break;
						// case 2:
						//   _this.zphpost.cJZ = e;

						//   break;

					default:
						break;
				}
			},
			posilistall(i) {
				var _this = this;
				var par = {
					types: "薪资",
				};
				posilistall(par).then((res) => {
					if (res.success) {
						_this.xzList = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//下拉框选取
			SelectChange(e) {
				var _this = this;
				_this.xzList.forEach((element) => {
					if (element.cCode == e) {
						_this.xzdata = element.cName;
					}
				});

				if (_this.xzdata != "面议") {
					var data = _this.xzdata.split("-");
					_this.zphpost.cDyb = data[0];
					_this.zphpost.cDye = data[1];
				} else {
					_this.zphpost.cMy = 1;
				}
			},

			//招聘会岗位列表
			onlineposicompanylist() {
				var _this = this;
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
					PageSize: _this.PageSize,
					PageNumber: _this.PageNumber,
					showLoadType: ".resume",
				};
				onlineposicompanylist(par).then((res) => {
					if (res.success) {
						res.data.rows.forEach((element) => {
							if (element.oF_FLZL_QT) {
								element.oF_FLZL_QT_list = element.oF_FLZL_QT.split(",");
							}
							if (element.oF_DEADLINE) {
								element.oF_DEADLINE = element.oF_DEADLINE.split("T")[0];
							}
						});
						_this.total = res.data.total;
						_this.postdata = res.data.rows;
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			//招聘会岗位重置数据
			zphpostAdd() {
				var _this = this;
				_this.zphpost = {
					id: 0, //岗位id
					zphid: _this.id, //招聘会ID(为0即为新增)
					iComid: _this.userinfo.coM_ID, //公司ID
					Zwmc: "", //岗位名
					cHy: "", //招聘会职位类别（传code_value
					cZprs: "1", //招聘人数，
					cDyb: "", //薪资起始，
					cDye: "", //薪资结束，
					cMy: 0, //是否面议
					cGwyq: "", //岗位描述，
					cHbys: "0", //毕业生岗位（应届毕业生=1，非应届毕业生=0）
					cXl: "", //学历（传code_value）
					cJZ: "", //招聘会紧缺度（传code_value），
					cZy: "", //专业要求
				};

				_this.zphsxdata[0].data = "";
				_this.zphsxdata[1].data = "";

				_this.xzdata = "";
				_this.zphdrawer = true;
				_this.edit = false;
			},
			//岗位新增
			onlineposicompany() {
				var _this = this;
				var zphpost = _this.zphpost;
				zphpost.cMy = zphpost.cMy ? 1 : 0;
				zphpost.cJZ = zphpost.cJZ ? 1 : 0;
				if (!zphpost.Zwmc) {
					_this.$message.warning("请输入岗位名称");
					return;
				}
				if (!zphpost.cHy) {
					_this.$message.warning("请选取岗位类型");
					return;
				}
				if (!zphpost.cDyb && zphpost.cMy != 1) {
					_this.$message.warning("请输入起始薪资");
					return;
				}
				if (!zphpost.cDye && zphpost.cMy != 1) {
					_this.$message.warning("请输入结束薪资");
					return;
				}
				if (this.cDaxing && !zphpost.cZy) {
					_this.$message.warning("请输入专业要求");
					return;
				}
				if (this.cDaxing && !zphpost.cXl) {
					_this.$message.warning("请选取学历");
					return;
				}

				onlineposicompany(this.zphpost).then((res) => {
					if (res.success) {
						if (this.edit) {
							_this.$message.success("岗位修改成功!");
						} else {
							_this.$message.success("岗位新增成功!");
						}

						_this.zphdrawer = false;
						_this.PageNumber = 1;
						_this.onlineposicompanylist();
					} else {
						_this.$message.error(res.msg);
					}
				});

				// if (_this.fairtype) {
				//   _this.confirmonlinecompanystatus(1);
				// } else {
				//   _this.confirmoffline(1);
				// }
			},
			zphpostedit(item) {
				var _this = this;
				_this.zphpost = {
					id: item.id, //岗位id
					zphid: item.zphid, //招聘会ID(为0即为新增)
					iComid: item.iComid, //公司ID
					Zwmc: item.zwmc, //岗位名
					cHy: item.cHy, //招聘会职位类别（传code_value
					cZprs: item.cZprs, //招聘人数，
					cDyb: item.cDyb, //薪资起始，
					cDye: item.cDye, //薪资结束，
					cMy: item.cMy == 1 ? true : false, //是否面议
					cGwyq: item.cGwyq, //岗位描述，
					cHbys: item.cHbys, //毕业生岗位（应届毕业生=1，非应届毕业生=0）
					cXl: item.cXl, //学历（传code_value）
					cJZ: item.cJZ == 1 ? true : false, //招聘会紧缺度（传code_value），
					cZy: item.cZy, //专业要求
				};
				// if (item.cMy == 1) {
				//   _this.xzdata = "面议";
				// } else {
				//   _this.xzdata = item.cDyb + "-" + item.cDye;
				// }

				_this.zphsxdata[0].data = item.zhiwei;
				_this.zphsxdata[1].data = item.xueli;
				// _this.zphsxdata[2].data = item.jinquedu;
				_this.zphdrawer = true;
				_this.edit = true;
			},
			//招聘会岗位删除
			delonlineposicompany(id) {
				var _this = this;
				var par = {
					id: _this.userinfo.coM_ID,
					uid: _this.id,
					zid: id,
				};
				delonlineposicompany(par).then((res) => {
					if (res.success) {
						_this.PageNumber = 1;
						_this.onlineposicompanylist();
						_this.$message.success("岗位已删除!");
					} else {
						_this.$message.error(res.msg);
					}
				});
			},
			postBind() {
				this.$emit("postBind", {
					data: true
				});
				/*if (this.postdata.length > 0) {
					this.$emit("postBind", {
						data: true
					});
				} else {
					this.$message.warning("请添加至少一个工作岗位！");
				}*/
			},
			//当前显示数选取
			SizeChange(val) {
				this.PageSize = val;
				this.PageNumber = 1;
				this.onlineposicompanylist();

				this.goPost();
			},
			//当前页选取
			CurrentChange(val) {
				this.PageNumber = val;
				this.onlineposicompanylist();
				this.goPost();
			},
			goPost() {
				let distance =
					document.documentElement.scrollTop || document.body.scrollTop; //获得当前高度
				let step = distance / 30; //每步的距离
				(function jump() {
					if (distance > 0) {
						distance -= step;
						window.scrollTo(0, distance);
						setTimeout(jump, 10);
					}
				})();
			},
			back() {
				this.$emit("back", {
					i: 2
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.mian {
		width: 1200px;
		margin: 20px auto;
	}

	.info_mian {
		background-color: #fff;
		padding: 20px;
		margin-top: 15px;

		.jobfair-item {
			background: #fff;
			padding: 16px 24px;
			transition: all 0.5s;
			margin-bottom: 12px;
			border-bottom: 1px solid #f8f8f8;

			.item-list {
				display: flex;
				align-items: center;
				margin-bottom: 16px;
				position: relative;

				.post-conpany {
					flex: 1;
					width: 100%;
					text-align: right;
					font-size: 18px;
					color: #333;
				}

				.post-num {
					flex: 1;
					width: 100%;
					text-align: right;
				}

				.deliver-btn {
					flex: 1;
					width: 100%;
					text-align: right;
					transition: all 1s;
					position: absolute;
					right: 0;
					top: -5px;

					.el-button {
						padding: 8px 20px;
					}
				}

				.post-name {
					font-size: 16px;
					color: #66b1ff;
					margin-right: 16px;
				}

				.post-data {
					font-size: 14px;
					color: #787878;
				}

				.post-salary {
					font-size: 16px;
					color: rgba(255, 118, 48, 1);
					margin-right: 16px;
				}

				.post-detail {
					font-size: 14px;
					color: #787878;

					span {
						// border-right: 1px solid #999;
					}

					b {
						margin: 0 5px;
					}
				}

				.post-num {
					font-size: 14px;
					color: #787878;

					b {
						color: #66b1ff;
					}
				}

				.post-welfare {
					color: #787878;
					font-size: 14px;

					span {
						padding: 4px 8px;
						font-size: 12px;
						color: #66b1ff;
						background: #eee;
						border-radius: 2px;
						margin-right: 12px;
					}
				}
			}

			.item-list:last-child {
				margin-bottom: 6px;
			}
		}
	}

	.postadd {
		padding: 5px 20px;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.Tips {
			font-size: 14px;
			margin-bottom: 10px;
		}

		.TipsM {
			margin-bottom: 30px;
		}

		.li {
			width: 50%;
			display: flex;
			align-items: center;
			margin-bottom: 20px;

			b {
				color: red;
			}

			.left {
				width: 20%;
				font-size: 14px;
				font-weight: bold;
			}

			.right {
				width: 80%;
				font-size: 14px;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 300px;
				}

				.inputx {
					width: 145px;
				}

				.labelselect {
					height: 40px;

					width: 300px;

					.el-input__inner {
						width: 300px;
					}
				}

				.el-input-number {
					width: 200px;
				}
			}
		}

		.lis {
			width: 100%;

			.left {
				width: 10%;
			}

			.right {
				width: 90%;

				input {
					width: 200px;
				}

				span {
					margin: 0 10px 0 20px;
				}
			}
		}

		.lix {
			width: 30%;
			display: flex;
			align-items: center;

			.left {
				width: 33%;
			}

			.right {
				width: 67%;

				input {
					width: 100px;
				}

				span {
					margin: 0 10px 0 20px;
				}

				.labelselect {
					height: 40px;
					width: 202px;

					.el-input__inner {
						width: 202px;
					}
				}
			}
		}

		.lixs {
			width: 20%;
			display: flex;
			align-items: center;

			.left {
				width: 50%;
				padding-left: 30px;
			}

			.right {
				width: 50%;

				span {
					margin: 0 10px 0 20px;
				}
			}
		}

		.ul {
			width: 100%;
			margin-bottom: 20px;

			.left {
				font-weight: bold;
				font-size: 14px;
				margin-bottom: 10px;
			}

			.right {
				width: 100%;
				font-size: 14px;

				input {
					height: 40px;
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					width: 100%;
				}

				.labelselect {
					height: 40px;

					width: 100%;

					.el-input__inner {
						width: 100%;
					}
				}

				textarea {
					box-sizing: border-box;
					border: 1px solid rgba(193, 189, 189, 0.4);
					border-radius: 4px;
					text-indent: 1em;
					padding: 10px 5px;
					width: 100%;
				}
			}
		}

		.btn {
			width: 100%;
			display: flex;
			justify-content: center;
			margin-top: 20px;

			.cancel,
			.preservation {
				width: 200px;
				height: 40px;
				line-height: 40px;
				text-align: center;
				margin: 0 15px;
			}

			.cancel {
				border: 1px solid #276bf2;
				box-sizing: border-box;
				color: #276bf2;
				cursor: pointer;
			}

			.preservation {
				background: #276bf2;
				color: white;
				cursor: pointer;
			}
		}
	}

	.page {
		width: 100%;
		background: white;
		height: 80px;
		line-height: 80px;

		.el-pagination {
			width: 100%;
			padding: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			/deep/.el-pager li {
				vertical-align: middle;
				color: #666;
				margin: 0 10px;
				font-weight: normal;
			}

			/deep/.el-pager li.active {
				color: #fff;
				background: #276bf2;
				border-radius: 4px;
			}

			/deep/.el-pager li:hover {
				color: #276bf2;
			}
		}
	}

	.jobfair-next {
		background: #fff;
		padding: 12px 15px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		text-align: center;

		.el-button+.el-button {
			margin-left: 30px;
		}

		.el-button {
			border-radius: 0;
			width: 200px;
		}
	}
</style>
